import { Axios } from "@/shared/axios"

const userService = {
    login: async (loginCredentials) => {
        try {
            let auth = await Axios.post("users/login", loginCredentials.value)
            return auth;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                return {
                  message: error.response.data.message,
                  status: error.response.status,
                };
            } else {
            return {
                message: "Invalid Credentials",
                status: 500
            };
          }
        }
    },

    validateResetEmail: async (data) => {
        try {
            let message = await Axios.post("users/password-reset", data)
            return message.data;
        } catch(error) {
            return {
                message: "Invalid Email",
                status: 401
            }
        }
    },

    resetPassword: async (data) => {
        return Axios.put("users/change-password", data);
    }
};

export default userService;